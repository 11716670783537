<template>
  <v-container id="reading" fluid tag="section">
    <base-material-card icon="mdi-file" inline class="px-5 py-3">
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light"><strong>{{ icpnum }}</strong> Meter Readings</div>
      </template>

      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        class="ml-auto"
        label="Search"
        hide-details
        single-line
        style="max-width: 300px"
      />

      <v-divider class="mt-3" />

      <v-data-table
        class="elevation-1"
        :headers="headers"
        :items="readings"
        :search.sync="search"
        :loading="isBusy"
        loading-text="Loading... Please wait"
        :sort-by="['read_date', 'meternum', 'channelnum']"
        :sort-desc="[true, false, false]"
        multi-sort
      />
    </base-material-card>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  name: 'MeterReading',

  data: () => ({
    isBusy: false,
    errorMessage: '',
    readings: [],
    search: '',
    headers: [
      { text: 'Meter', value: 'meternum' },
      { text: 'Channel', value: 'channelnum' },
      { text: 'Start Date', value: 'channel_start_date' },
      { text: 'End Date', value: 'channel_end_date' },
      { text: 'Read Date', value: 'read_date' },
      { text: 'Reading', value: 'reading' },
      { text: 'Read Type', value: 'read_type' }
    ]
  }),

  props: {
    icpnum: {
      required: true,
      type: String
    }
  },

  async created() {
    this.getReadings()
  },

  methods: {
    getReadings () {
      return new Promise((resolve, reject) => {
        axios.get('/api/icps/' + this.icpnum + '/readings', { withCredentials: true })
          .then(response => {
            this.isBusy = false
            this.readings = response.data
            resolve(response)
          })
          .catch(error => {
            this.errorMessage = 'Failed to load data'
            reject(error)
          })
          .finally(() => { this.isBusy = false })
      })
    }
  }
}
</script>

